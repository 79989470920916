<template>
    <div class="cnt">
        <div class="tit">Privacy policy</div>
        <p>GIGWI CORPORATION: PRIVACY, COOKIE, and DATA PROCESSING POLICY</p>
        <p style="margin-top: 15px;">This document was last updated August 4, 2023.</p>
        <p style="margin-top: 40px;">GIGWI, Inc. and its affiliates and subsidiaries (“GIGWI”or“we”,“our”,“us”) respect your
            concerns about privacy. This Privacy Policy (“Privacy Policy”) explains the type of information we may obtain
            from individuals who visit our websites (each, a “Site” and collectively, “Sites”) or use our mobile
            applications (each, an “App” and collectively, “Apps”), which includes tools such as chat available through our
            Sites and Apps. When we use the term“including”, it shall mean“including but not limited to”. In addition, this
            Privacy Policy sets out how GIGWI may use, disclose and protect your personal information.</p>
        <p style="margin-top: 15px;">By accessing the Platforms on any computer, mobile phone, tablet, or other device
            (collectively,“Device”); making a purchase from GIGWI; or otherwise communicating or interacting with GIGWI, you
            agree to the terms of this Policy. If you do not agree to the terms of this Policy, please do not use the
            Platforms.</p>
        <p style="margin-top: 15px;">We may modify this Policy at any time and will post the current version on the Sites as
            further detailed in the “Changes to the Privacy Policy” section below. We encourage you to periodically review
            this Policy to stay informed about how we are using the information we collect.</p>
        <div class="title" style="margin-top: 46px;">1. INFORMATION WE OBTAIN. </div>
        <p>Information you give us. We collect and maintain information that you may provide to us through your use of our
            Services, including your name, shipping address, billing address, payment card information, telephone number(s),
            email address(es) and purchase/return/exchange data and pet information. For example, when you:</p>
        <div class="tips">
            <p>• set up your account and/or make online purchases, set up your GIGWI account
                registration, you will be asked
                to provide information necessary to facilitate the transaction, such as contact and payment information);
            </p>
            <p>• seek assistance from our customer service team or veterinarians, through email,
                telephone, automated and live chat sessions, SMS or any other channels we may ask you questions about your
                pet or otherwise collect and record additional information from or about you during those interactions;</p>
            <p>• enter our contests, sweepstakes or other promotions, you may be asked for information
                necessary to fulfill the promotion;</p>
            <p>• post comments or other content in publicly-available forums on our Services (eg. ,
                Product reviews, Q&A forum);</p>
            <p>Information we obtain by automated means.We may use technology to automatically obtain other information
                about you and your devices when you visit our Sites, use our Apps or view our advertisements online.For more
                information, see the “Cookies and Interest-Based Advertising” section of this Privacy Policy, below. This
                information helps our Sites and Apps work correctly and supports our marketing and analytics efforts.This
                information includes, but is not limited to, device type; device settings;Internet browser
                information; operating system information;IP address;mobile device identifiers;software version;phone
                model;phone operating system;mobile phone carrier information; precise geolocation data;the date, time and
                length of your stay on our Sites and Apps;and the referring website, specific pages accessed and other
                actions you take on our Sites and Apps.Additionally, your chats, phone/video calls and other interactions
                with our customer service team or veterinarians may be recorded.We may obtain other information from you
                from time to time by providing appropriate notice.</p>
            <p>We also use a third-party session replay service that records users’ interactions with our Sites and Apps,
                including users’ clicks, mouse movements and scrolls.</p>
            <p>Information we obtain from other sources. We may receive information about you from other sources to, for
                example, help us correct or supplement our records, improve the quality of our Services, prevent or detect
                fraud and support our marketing and analytics.Such sources may include information collected from payment
                processors, data brokers, third-party ad technology companies, veterinarians (such as specific pet health
                information, diseases or conditions, prescriptions, treatments), other pet service providers (such as
                groomers), insurance companies, shelters, and shipping carriers.We may use this information consistent with
                this Privacy Policy.</p>
            <p>Third-Party Web Analytics Services.We use third-party web analytics services on our Sites and Apps, such as
                Google Analytics.he service providers that administer these services use automated technologies to collect
                data (such as IP addresses, cookies, and other device identifiers) to evaluate the use of our Services.To
                learn more about Google Analytics and how to opt out, please visit
                https://support.google.com/analytics/answer/181881?hl=en.</p>
        </div>
        <div class="title" style="margin-top: 46px;">2. Additional Purposes for Using Personal Information</div>
        <p>We may use, process and disclose the information we collect about you for our business purposes, including:</p>
        <div class="tips">
            <p>Fulfilling orders, providing Services, and responding to your questions and requests.Approvals and
                assistance, offering new products and services, and ensuring the proper delivery of the products and
                services you order from us.</p>
            <p>Creating and maintaining your GIGWI account, communicating with you. In addition, we may use your information
                to contact you about: </p>
            <p>(1) updates to this Privacy Policy or our Terms of Use; </p>
            <p>(2) changes to our Services,products and programs;</p>
            <p>(3) reviews, comments or other feedback you provide about our products or Services;</p>
            <p>(4) issues with your orders or product recalls. </p>
            <p>Improving and personalizing our products and Services.We may use your information to make improvements to our
                products and Services, as well as personalize our Services to meet your preferences and needs, customize
                your experience with us and provide you with a consistent experience across the devices you use.
            </p>
            <p>Reviewing Site and App trends and customer interests.We may also use your information to analyze the way you
                use our Sites and Apps and understand your interests, including by combining information we receive from you
                and your devices with other information about you that we receive from other sources.
            </p>
            <p>Sending and improving marketing communications.We may send you communications about promotions or offers via
                regular mail, email, text messaging or other electronic channels, including ads on Social Media Services.We
                may use information, including information collected across different online services and from the various
                devices you may use, for marketing and analytics purposes and to deliver marketing communications based on
                your behavior and interests.
            </p>
            <p>Promoting our products or Services.If you complete a product review, submit feedback or provide an image of
                your pet(s), we may use your submission for commercial purposes, to promote our products and Services, or to
                send you personalized content, including thank you notes and holiday cards.
            </p>
            <p>Administering promotions, such as sweepstakes and contests.We may use your information to administer
                sweepstakes, contests, and other promotions if you choose to participate.
            </p>
            <p>Detecting and preventing fraud.We may use your information to protect against, identify and prevent fraud and
                other criminal activity, claims and other liabilities.
            </p>
            <p>Complying with law.We may use your information to comply with and enforce applicable legal requirements
                (including to maintain pet health records and authorizations), relevant industry standards and our policies,
                including this Privacy Policy and our Terms of Use.
            </p>
            <p>Other uses.We may use your information to operate, evaluate, develop, manage and improve our business
                (including operating, administering, analyzing, and improving our Services;developing new products and
                services;managing and evaluating the effectiveness of our communications;conducting market research,
                aggregating and anonymizing data, and performing data analytics;facilitating the provision of services to
                GIGWI;and performing accounting, auditing, and other internal functions).
            </p>
            <p>We also may use your information in other ways for which we provide specific notice at the time of collection
                or otherwise with your consent.
            </p>
        </div>
        <div class="title" style="margin-top: 46px;">3. Cookie Policy</div>
        <p>Like many commercial websites, we analyze how visitors use the Platforms through what is known as "cookie"
            technology. A cookie is a small text file that is placed on your computer when you access the Platforms and
            allows us to recognize you each time you visit the Sites. We may use cookies to:</p>
        <div class="tips">
            <p>(1) allow you to use the Platforms without having to reenter your username;</p>
            <p>(2) enhance or personalize your Platform usage and shopping experience; </p>
            <p>(3) monitor Platform usage;</p>
            <p>(4) manage the Platforms;</p>
            <p>(5) improve the Platforms and our products and services, including providing you with interest-based ads.</p>
            <p>For more information on our advertising, see the “Interest-Based Advertising" section below. If you choose,
                you can set your browser to reject cookies or you can manually delete individual cookies or all of the
                cookies on your computer by following your browser’s help file directions. However, if your browser is set
                to reject cookies or you manually delete cookies, you may have some trouble accessing and using some of the
                pages and features that are currently on the Platforms, or that we may put on the Platforms in the future.
                Note that browser-management tools for cookies are outside of our control and we cannot guarantee their
                effectiveness.</p>
            <p>We may also use web beacons on the Platforms, in our emails, and in our advertisements on other websites. Web
                beacons are tiny graphic images that are used to collect information about your visit to the Platforms, such
                as the pages you view and the features you use, as well as information about whether you open and/or act
                upon one of our emails or advertisements. We may also collect the URL of the website you visited immediately
                before coming to the Platforms. Web beacons help us analyze the Platform visitors’ behavior and measure the
                effectiveness of the Platforms and our advertising. We may work with service providers that help us track,
                collect and analyze this information.
            </p>
            <p>We may combine the information we collect through cookies and web beacons with other information we have
                collected from you or information from other sources.
            </p>
        </div>
        <div class="title" style="margin-top: 46px;">4. Third Party Collection & Service Providers</div>
        <p>INFORMATION WE DISCLOSEWe may share information about you with third parties in order to conduct our business,
            when legally required, or with your consent, including:
        </p>
        <div class="tips">
            <p>Affiliates and Subsidiaries.We may share your information with our affiliates and subsidiaries for business,
                operational, promotional, marketing or other purposes consistent with this Privacy Policy.
            </p>
            <p>Service Providers.We may disclose the information we obtain about you to service providers who perform
                services on our behalf (e.g., to fulfill orders, deliver packages, send postal mail and/or email, analyze
                data, provide marketing research and assistance, process credit card payments, detect and prevent fraud).Our
                service providers are restricted from using your personal information in any way other than to provide
                services for us.
            </p>
            <p>Business Partners.With your consent and at your direction, we may share your and your pet’s information with
                our business partners so they can provide you with the services that you request or that may interest you.
            </p>
            <p>Other Third Parties.We may disclose your information to other third parties in connection with our marketing
                efforts to promote our Services, or as otherwise directed by you.
            </p>
            <p>usiness Transfer.We may disclose, transfer, or assign to our affiliates, or to one or more third parties, the
                information collected about you in the event that we sell or transfer (or contemplate the sale or transfer
                of) all or a portion of the assets or business of GIGWI, including as part of an actual or contemplated
                merger, acquisition or joint venture.In the unlikely event of our bankruptcy, insolvency, reorganization,
                receivership, liquidation, divestiture, dissolution or assignment for the benefit of creditors, or the
                application of laws or equitable principles affecting creditors’ rights generally, we may transfer your
                personal information to a successor or to a third party that purchases our assets arising from such
                circumstances. In such transactions, customer information generally is one of the transferred business
                assets but remains subject to the promises made in any pre-existing Privacy Policy.</p>
            <p>Protection of GIGWI and Others.We may disclose information about you to the government or to other third
                parties to comply with applicable law and regulations, governmental and quasi-governmental requests, court
                orders, subpoenas and other legal process.We may also disclose information to third parties if necessary to:
            </p>
            <p>(i) enforce any of the Terms of Use for our Services or any investigation of potential violations thereof,
            </p>
            <p>(ii) detect, prevent, or otherwise address fraud, security or technical issues, </p>
            <p>(iii) protect against harm to our Services</p>
            <p>(iv) protect our rights, property or safety, or the rights, property or safety of our users or others.</p>
        </div>
        <div class="title" style="margin-top: 46px;">5. Children’s Privacy</div>
        <p>The Platforms are not directed to children under the age of 13, and GIGWI will never knowingly collect personal
            or other information from anyone it knows is under the age of 13. Persons over 13 but under 18 years of age may
            use the Platforms only in conjunction with and under the supervision of a parent or legal guardian.
        </p>
        <div class="title" style="margin-top: 46px;">6. Data Security</div>
        <p>We implement reasonable administrative, technical, and physical safeguards designed to protect the information we
            collect.</p>
        <div class="title" style="margin-top: 46px;">7. Your Privacy Rights</div>
        <div class="tips">
            <p>You can stop email marketing from us by using the “opt out,” or “unsubscribe” mechanism at the bottom of our
                email marketing messages. We may give you a choice about stopping just one kind of email or opting out of
                all
                email marketing from us.
            </p>
            <p>By texting us in the context of customer service, you consent to receive transactional text messages from
                GIGWI
                at the mobile number you used to text and you are opting-in to receive future messages or a phone call to
                the
                number you provided.
            </p>
            <p>You can opt-out of receiving promotional and transactional text messages via our Messaging Service by
                responding
                to any of our text messages with any of the following replies: STOP, END, CANCEL, UNSUBSCRIBE, or QUIT. For
                additional opt-out information, please review our FAQs.
            </p>
            <p>If you want to learn more about the personal information that GIGWI has about you, or you would like to
                update or
                change that information, or deactivate your account, please contact us by email at privacy@barkbox.com. Note
                that deactivating your account does not cause your account information to be deleted from our servers.
            </p>
        </div>
        <div class="title" style="margin-top: 46px;">8. European and UK Residents’ Privacy Rights</div>
        <p>In addition to the rights described in the “Your Privacy Rights” section above, if you are a European or UK
            resident individual, you have certain rights in relation to your personal information under the applicable
            European and UK data privacy laws.
        </p>
        <div class="tips">
            <p>A. Your Rights to Control Your Personal Information</p>
            <p>Applicable European and UK data privacy laws give certain visitors the right to access their personal
                information. Your right of access can be exercised in accordance with the European and UK data privacy laws.
                If you would like to request a copy of your personal information being held by us or request that it be
                deleted, or to update and/or correct your personal information or request that we provide a copy to another
                data controller of your personal information that you have provided to us, please contact us as detailed in
                the “Contact Us” section below. We will need enough information to ascertain your identity as well as the
                nature of your request. We will aim to respond to your request within one (1) calendar month of receipt of
                the request. Where we were unable to do so within the calendar month, we will notify you of the soonest
                practicable time within which we can respond to your request (and within three (3) months from the date of
                your request).
            </p>
            <p>There are certain exemptions and restrictions of these rights under the European and UK data privacy laws
                that enable personal information to be retained, processed or withheld from access and we will inform you of
                these exemptions and restrictions, if applicable. If you would like to exercise any of these rights, please
                contact us as detailed in the “Contact Us” section below.
            </p>
            <p>B. We Use Your Personal Information for the Following Key Purposes:</p>
            <p>to contact you: subject to applicable law, we or our third-party service and business providers may send you
                communications; especially if you have subscribed to one of our services. Such communications are designed
                to make your experience of with Platforms more efficient and may include, but are not limited to,
                communications about us and other notifications, including important news that could affect your
                relationship with us. Where required under applicable data privacy laws, we will not send you marketing
                communications without your prior consent;
            </p>
            <p>for legal purposes: we may use and share personal information for legal purposes, including financial,
                regulatory, tax and other legal obligations and to respond to governmental or regulatory requests or
                subpoenas or for litigation purposes;
            </p>
            <p>for our legitimate interests and those of a third party: we may use your personal information to manage our
                legal, regulatory, financial and business requirements, including obtaining legal advice, in the course of
                disputes and litigation, internal and/or regulatory investigations and other legitimate interests; we also
                use your personal information for our legitimate marketing purposes and we may share your personal
                information with third parties, such as our commercial partners and vendors, where we consider this meets
                our legitimate interests or those of our commercial partners and vendors, whilst respecting our visitors’
                data privacy rights;
            </p>
            <p>for our business purposes: we may use your personal information to help us manage the objectives of our
                organization;
            </p>
            <p>for other purposes: subject to applicable law, we may use your personal information for additional purposes
                in connection with the Platforms, where you have provided your prior consent.
            </p>
            <p>C. Sharing Your Personal Information</p>
            <p>We share your personal information with third parties to help us use your personal information, as described
                in this Policy and in addition we may need to share your personal information with our professional tax,
                legal, audit or financial advisors as well as sponsors, members, and other organizations in the proper
                course of our business. We may also share your personal information when we receive requests for information
                in the context of legal disputes, investigations regulatory enquiries or similar requests. Where
                appropriate, we execute EU and UK Model Clauses, also known as Standard Contractual Clauses, to meet the
                adequacy and security requirements for the transfer of personal information to countries that lack adequacy
                determines. Please contact us as detailed in the “Contact Us” section below for more information.
            </p>
            <p>D. Complaint Process</p>
            <p>We are transparent about the ways in which we collect and use personal information and welcome your questions
                and concerns. If you have any concerns or complaints about the way we handle your personal information,
                please contact us as detailed in the “Contact Us” section below. To the extent you believe we have not
                addressed your concerns or otherwise choose to do so, you have the right to lodge a complaint with a
                supervisory authority in your country of residence under the European and UK data privacy laws.
            </p>
        </div>
        <div class="title" style="margin-top: 46px;">9. Changes to this Policy</div>
        <p style="margin-bottom: 50px;">We reserve the right to amend this Policy at any time. We will post all changes to this Policy on the Sites, so
            you should review this Policy periodically. Any such amendments to this Policy may apply to information we
            collect in the future as well as any information we obtained prior to such amendment. If we make a material
            change to this Policy, we will provide appropriate notice as required by law.
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.cnt {
    width: 1280px;
    margin: 0 auto;
    color: #000;
}

.tit {
    font-size: 36px;
    font-weight: bold;
    margin: 69px 0 31px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.tips p {
    margin-top: 15px;
}</style>